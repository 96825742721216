import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
const AboutUsComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Box className="AboutUs_title">{t("About_Us")}</Box>

        <Box className="AboutUs_second_title">Quality Equipment</Box>
        <Box className="AboutUs_Sub_title">Marcel Equipment Limited was established over 48 years ago in 1976 by Marcel LeHouillier and has grown to be a Leading Independent Used Equipment Dealer offering our High Quality, Rebuilt and Reconditioned Equipment to our customers in the Landfill, Construction, Aggregate and Mining Industries throughout the world.</Box>
        <Box className="AboutUs_Sub_title">Marcel started the company as the sole employee and eventually brought on a mechanic, who happened to be his brother, with a focus on selling quality used equipment. Over the years and as the company grew, Marcel started to focus on the being a leading supplier to the landfill industry. With this, came a focus of fully reconditioning and rebuilding landfill compactors and dozers. As a result, the company grew to employ additional technicians, welders and painters.  Currently today Marcel Equipment employs over 20 full time employees and relies on some trusted outside mechanical contractors and area OEM dealers whom we have close relationships with. Many of the employee’s have been with the company for over 30 years.</Box>
        <Box className="AboutUs_Sub_title">A lot of things have changed over all those years including New Ownership by Groupe Poisson back in 2017, but our experienced Management Team of Mark Frederick and John Stewart and our Dedicated long-term employees, who are the backbone of our great company, have all mostly remained the same.</Box>
        <Box className="AboutUs_Sub_title">We own what we sell…we are not brokers! We will provide you with a Quality, Work Ready piece of equipment that has been thoroughly Inspected, Rebuilt, Reconditioned or a combination of both, within our facilities by our skilled and knowledgeable staff. We are so confident in our final product that we sell them with Warranty! We stand behind our product and all of our employees are proud of each machine that leaves here with the Marcel Equipment name on it.</Box>
        <Box className="AboutUs_Sub_title">We are fully transparent in everything we do.  We provide complete and factual details on each machine including; visual inspection results, operational testing results, pressure testing results, current and previous repair history, Cat undercarriage inspection reports, pictures and specifications. We take pride in providing you with complete details on everything we know. We go above and beyond anyone else in the industry to ensure that you know the full details on what you are purchasing. Nothing leaves our yard with a known issue. We welcome you to hire an independent inspection on any unit utilizing local OEM dealers or even better, we welcome you to come visit us yourself. </Box>
        <Box className="AboutUs_Sub_title">Our pricing can include delivery to anywhere within North America, Ocean port or your final destination. We can handle or assist with all export or customs documents for delivery around the world.</Box>
        <Box className="AboutUs_Sub_title">Our name is on every piece of equipment that leaves our yard and we want to ensure that each equipment purchase will meet or exceed your expectations and you continue to do business with us in the future. Our business success is based on the reputation Marcel Equipment as built over the last 45 years. Many of our customers are repeat buyers and we are proud of the long-term relationships we have built with them over the years. From the quality workmanship from our trades people to the professional handling of the purchase from the sales staff to the delivery of the unit from our contracted freight company, we guarantee we will meet or exceed your expectations.</Box>
        <Box className="AboutUs_Sub_title">It was learned early on that every customer is an important customer. No matter the company size, name or history, everyone deserves the same level of service. Every company starts somewhere, at some point, and we welcome the opportunity to be a small part of any company’s history and success. Long-term successful business relationships allow us to continue with Marcel Equipment’s legacy. We will always remember that our Customers are the ones that keep our doors open.</Box>
        <Box className="AboutUs_Sub_title">That is why Marcel Equipment Limited is… <b>“Doing it Right Today to Earn Your Business Tomorrow”</b></Box>
      
      </Box>
    </>
  );
};
export default AboutUsComponent;
