import { Box } from "@mui/system";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MachineListComponent, {
  MachineBox,
  MachineBoxUseCopy,
} from "./MachineListComponent/MachineListComponent";
import { useTranslation } from "react-i18next";
import {
  getAllMachinesList,
  postMachinesList,
} from "../../services/machineService";
import { ApplicationId } from "../../api/url";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyIcon from "../../assets/img/images/copy.png";
import { Path } from "../../Utils/ScreenPath";

const EquipmentComponent = ({ AttachmentsListPage }) => {
  const { state } = useLocation();
  const { id } = useParams();
  const [showSliderModel, setShowSliderModel] = useState(true);
  const [assetStartingLoader, setAssetStartingLoader] = useState(true);
  const [
    assetCategorizationSummaryLoading,
    setAssetCategorizationSummaryLoading,
  ] = useState(true);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectCategoryType, setSelectCategoryType] = useState();
  const [AssetTypeList, setAssetTypeList] = useState([]);
  // -----------
  const [pageRefresh, setPageRefresh] = useState(false);
  const [assetTypeId, setAssetTypeId] = useState("");
  const [machineTypeSelectIdCategoryName, setMachineTypeSelectIdCategoryName] =
    useState("");
  const [allFilterID, setAllFilterID] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [page, setPage] = useState(1);
  const [isMachineData, setIsMachineData] = useState([]);
  const [dataLoadLoading, setDataLoadLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [machinesTypeList, setMachinesTypeList] = useState([]);
  const [categoryofMachineList, setCategoryofMachineList] = useState();

  const [selecedEquipment, setSelecedEquipment] = useState([]);
  const [selectedCheckList, setSelectedCheckList] = useState(false);
  const [copyMachine, setCopyMachine] = useState(false);

  const limit = 9;
  const navigate = useNavigate();

  const [assetList, setAssetList] = useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });
  const { t } = useTranslation();

  const GetMachinesList = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    GetMachinesList();
    if (window.location.href.split("userID=")?.[1]) {
      localStorage.setItem(
        "UserID",
        window.location.href.split("userID=")?.[1]
      );
    }
  }, []);

  useEffect(() => {
    GetMachinesTypeList();
  }, []);
  const GetMachinesTypeList = (val) => {
    setAssetCategorizationSummaryLoading(true);
    getAllMachinesList()
      .then((res) => {
        const WithoutAttachmentsList = res?.data;

        setAssetTypeList(WithoutAttachmentsList);
        setAssetCategorizationSummaryLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // ----------------
  const GetMachinesListSidebar = (val) => {
    if (isMachineData?.length === 0) {
      setIsLoading(true);
    }
    setDataLoadLoading(true);
    setCategoryofMachineList(undefined);
    let payload;

    if (allFilterID) {
      payload = {
        AssetTypeId:
          assetTypeId && assetTypeId !== undefined
            ? JSON.parse(assetTypeId)
            : null,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
        Tier1Id: JSON.parse(allFilterID[2]),
        Tier2Id: JSON.parse(allFilterID[3]),
        Tier3Id: JSON.parse(allFilterID[4]),
        CategoryId: JSON.parse(allFilterID[5]),
      };
    } else if (val) {
      // ------------------ Split zero CategoryName ------------------
      // ------------------ Split One AssetTypeId --------------------
      // ------------------ Split two Tier1Id --------------------
      // ------------------ Split three Tier2Id --------------------
      // ------------------ Split four Tier3Id ------------------
      // ------------------ Split five CategoryId ------------------
      const IDs = val?.split(",");
      payload = {
        AssetTypeId: JSON.parse(IDs?.[1]) ?? null,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
        Tier1Id: JSON.parse(IDs?.[2]) ?? null,
        Tier2Id: JSON.parse(IDs?.[3]) ?? null,
        Tier3Id: JSON.parse(IDs?.[4]) ?? null,
        CategoryId: JSON.parse(IDs?.[5]) ?? null,
      };
    } else {
      payload = {
        AssetTypeId: assetTypeId,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
      };
    }
    payload = {
      ...payload,
      IsOldiesByGoodies: false,
      SortColumn: "SortOrder ASC",
    };

    payload = {
      ...payload,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        const machineData = res && res.data;
        setTotalRecords(machineData?.TotalRecords);
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines);
          setPage(machineData?.PageNumber);
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines]);
          setPage(machineData?.PageNumber);
        }
        setIsLoading(false);
        setAssetStartingLoader(false);
        setDataLoadLoading(false);
      })
      .catch((err) => {
        setAssetStartingLoader(false);
      });
  };
  const handleOnChangeTypePreviousPageDetails = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    const IDs = e.split(",");
    setPageNumber(1);
    setAllFilterID(IDs);
    setMachineTypeSelectIdCategoryName(IDs[0]);
    setAssetTypeId(IDs[1]);
    GetMachinesListSidebar(e);
  };

  const handleOnChangeType = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    setIsMachineData([]);
    setPage(1);
    if (e !== "") {
      const IDs = e.split(",");
      setPageNumber(1);
      setAllFilterID(IDs);
      setMachineTypeSelectIdCategoryName(IDs[0]);
      if (IDs[1] !== undefined) {
        setAssetTypeId(IDs[1]);
      }
    } else {
      setAllFilterID(null);
      setMachineTypeSelectIdCategoryName("");
      setAssetTypeId("");
    }
  };

  const handelSelectAssetTypeList = async (item, Type) => {
    setSelectCategoryType(item);
    // resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        ShowBackBTN: true,
      });
      handleOnChangeType(
        `${null},${item?.AssetTypeId},${null},${null},${null},${null}`
      );
    } else if (Type?.Type === "CategoryDetails") {
      handleOnChangeType(
        `${item?.CategoryName},${2},${null},${null},${null},${item?.CategoryId}`
      );
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${
                assetList?.selectAssetDetails?.AssetTypeId
              },${null},${null},${null},${
                assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${
                assetList?.selectAssetDetails?.AssetTypeId
              },${item?.Tier1Id},${null},${null},${
                assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${
                assetList?.selectAssetDetails?.AssetTypeId
              },${assetList?.Tier1Select?.Tier1Id},${item?.Tier2Id},${null},${
                assetList?.CategoryDetails?.CategoryId
              }`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };

  useEffect(() => {
    if (
      !AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      // ------------------ categoryofMachineList zero AssetTypeId ------------------
      // ------------------ categoryofMachineList One CategoryId --------------------
      // ------------------ categoryofMachineList two Tier1Id --------------------
      // ------------------ categoryofMachineList three Tier2Id --------------------
      // ------------------ categoryofMachineList four Tier3Id ------------------
      // ------------------ categoryofMachineList Five Tier4Id ------------------
      // ------------------ categoryofMachineList Six Category Name ------------------

      handleOnChangeTypePreviousPageDetails(
        `${categoryofMachineList[6] ?? null},${
          categoryofMachineList[0] ?? null
        },${categoryofMachineList[2] ?? null},${
          categoryofMachineList[3] ?? null
        },${categoryofMachineList[4] ?? null},${
          categoryofMachineList[1] ?? null
        }`
      );
    } else if (
      AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      setMachineTypeSelectIdCategoryName(categoryofMachineList[1] ?? "");
      GetMachinesListSidebar(categoryofMachineList[1] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesTypeList, categoryofMachineList]);

  useEffect(() => {
    if (allFilterID) {
      GetMachinesListSidebar();
    }
  }, [
    pageNumber,
    machinesTypeList,
    allFilterID,
    machineTypeSelectIdCategoryName,
  ]);

  useEffect(() => {
    if (state) {
      setIsLoading(true);
      setIsMachineData([]);
      setPage(1);
      handelSelectAssetTypeList(state, {
        Type: "CategoryDetails",
      });
    }
  }, []);

  const categoryId = id.split("-")?.[0];

  useEffect(() => {
    if (categoryId) {
      if (!assetCategorizationSummaryLoading && !isLoading && AssetTypeList) {
        const selecedData = getCategoryList(AssetTypeList)?.find(
          (item) => Number(item.CategoryId) === Number(categoryId)
        );
        if (selecedData) {
          handelSelectAssetTypeList(selecedData, {
            Type: "CategoryDetails",
          });
        } else {
          navigate("/");
        }
      }
    }
  }, [assetCategorizationSummaryLoading, categoryId]);

  const getCategoryList = (data) => {
    const CategoryData = data.find((item) => item.AssetTypeId === 2);
    // Order of categories you specified
    const desiredOrder = [38, 40, 39, 23, 44, 36, 35, 41];

    const filteredCategories = [];
    CategoryData?.CategoryDetails.forEach((item) => {
      const indexInDesiredOrder = desiredOrder.indexOf(item?.CategoryId);
      if (indexInDesiredOrder !== -1) {
        // If the item is found in the desiredOrder, add it to filteredCategories
        filteredCategories[indexInDesiredOrder] = item;
      }
    });

    const resultCategories = filteredCategories.filter(
      (item) => item !== undefined
    );

    return resultCategories || [];
  };

  const componentCopyRef = useRef();

  const Copy = () => {
    const range = document.createRange();
    range.selectNode(componentCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  };

  useEffect(() => {
    if (copyMachine) {
      Copy();
    }
  }, [copyMachine]);

  return (
    <>
      <Box className={selectCategoryType ? "p-l-14 p-b-50" : "p-x-20 p-b-50"}>
        {copyMachine ? (
          <Box className="dashboard responsive_dashboard">
            <Box className="d-flex flex-col" minHeight={"50vh"}>
              <Box
                mr={"10px"}
                sx={{ cursor: "pointer" }}
                className="d-flex"
                justifyContent={"flex-end"}
              >
                <img
                  src={CopyIcon}
                  alt=""
                  width={"16px"}
                  onClick={() => setCopyMachine(!copyMachine)}
                />
              </Box>
              <table
                className="d-flex flex-col"
                ref={componentCopyRef}
                style={{ minHeight: "50vh" }}
              >
                <tbody>
                  {selecedEquipment.map((MachineDetails, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <MachineBoxUseCopy
                            MachineDetails={MachineDetails}
                            notShowCheckBox
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </Box>
        ) : (
          <Box className="dashboard responsive_dashboard">
            {!assetCategorizationSummaryLoading && !isLoading ? (
              <Box
                className={
                  selectCategoryType
                    ? "dashboard_selected_left_box"
                    : "dashboard_left_box"
                }
              >
                {getCategoryList(AssetTypeList).map((link) => {
                  return (
                    <Box
                      className={
                        selectCategoryType?.CategoryId === link?.CategoryId
                          ? "dashboard_active_navigon_ink_box"
                          : "dashboard_navigon_ink_box"
                      }
                      onClick={() => {
                        navigate(
                          `/${
                            link?.CategoryId
                          }-${link?.CategoryName.toLowerCase().replaceAll(
                            " ",
                            "-"
                          )}`
                        );
                      }}
                    >
                      {link.CategoryName}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
            {isLoading || assetStartingLoader ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "30px",
                  height: "60vh",
                  alignItems: "center",
                }}
              >
                <div className="spinner-container">
                  <div className="loading-spinner"></div>
                </div>
              </div>
            ) : (
              <Box className="dashboard_right_box_without_animation">
                {isMachineData.length === 0 && !isLoading ? (
                  <Box className="MachineList_Category_box">
                    <Box className="MachineList_Category_title">
                      {selectCategoryType?.CategoryName}
                    </Box>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "30px",
                        height: "40vh",
                        alignItems: "center",
                      }}
                    >
                      <div className="spinner-container">
                        <Box className="No_Machine">No Machine Available</Box>
                      </div>
                    </div>
                  </Box>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={isMachineData.length}
                      next={() =>
                        totalRecords >= isMachineData?.length
                          ? setPageNumber(page + 1)
                          : null
                      }
                      hasMore={true}
                      loader={null}
                    >
                      <MachineListComponent
                        copyMachine={copyMachine}
                        setCopyMachine={setCopyMachine}
                        selecedEquipment={selecedEquipment}
                        setSelecedEquipment={setSelecedEquipment}
                        selectCategoryType={selectCategoryType}
                        selectedCheckList={selectedCheckList}
                        isMachineData={isMachineData}
                        setSelectedCheckList={setSelectedCheckList}
                      />
                    </InfiniteScroll>
                    {!isLoading && dataLoadLoading ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "30px",
                          height: "10vh",
                          alignItems: "center",
                        }}
                      >
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
export default EquipmentComponent;
