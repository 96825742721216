export default {
  Logo: require("./img/images/logo.png"),
  Machine:require("./img/images/machine.jpg"),
  LinkLogo1:require("./img/images/Logo-1.jpg"),
  LinkLogo2:require("./img/images/Logo-2.jpg"),
  LinkLogo3:require("./img/images/Logo-3.png"),
  LinkLogo4:require("./img/images/Logo-4.gif"),
  LinkLogo5:require("./img/images/Logo-5.jpg"),
  LinkLogo6:require("./img/images/Logo-6.jpg"),
  LinkLogo7:require("./img/images/Logo-7.gif"),
  LinkLogo8:require("./img/images/Logo-8.jpg"),
  LinkLogo9:require("./img/images/Logo-9.jpg"),
  LinkLogo10:require("./img/images/Logo-10.gif"),
  LinkLogo11:require("./img/images/Logo-11.jpg"),
  LinkLogo12:require("./img/images/Logo-12.gif"),
  LinkLogo13:require("./img/images/Logo-13.png"),
  GalleryLogo1:require("./img/images/Gallery_Logo_1.jpg"),
  GalleryLogo2:require("./img/images/Gallery_Logo_2.jpg"),
  GalleryLogo3:require("./img/images/Gallery_Logo_3.jpg"),
  GalleryLogo4:require("./img/images/Gallery_Logo_4.jpg"),
  GalleryLogo5:require("./img/images/Gallery_Logo_5.jpg"),
  GalleryLogo6:require("./img/images/Gallery_Logo_6.jpg"),
  GalleryLogo7:require("./img/images/Gallery_Logo_7.jpg"),
  GalleryLogo8:require("./img/images/Gallery_Logo_8.jpg"),
  GalleryLogo9:require("./img/images/Gallery_Logo_9.jpg"),
  GalleryLogo10:require("./img/images/Gallery_Logo_10.jpg"),
  GalleryLogo11:require("./img/images/Gallery_Logo_11.jpg"),
  GalleryLogo12:require("./img/images/Gallery_Logo_12.jpg"),
  GalleryLogo13:require("./img/images/Gallery_Logo_13.jpg"),
  GalleryLogo14:require("./img/images/Gallery_Logo_14.jpg"),
  GalleryLogo15:require("./img/images/Gallery_Logo_15.jpg"),
  GalleryLogo16:require("./img/images/Gallery_Logo_16.jpg"),
  GalleryLogo17:require("./img/images/Gallery_Logo_17.jpg"),
  GalleryLogo18:require("./img/images/Gallery_Logo_18.jpg"),
  GalleryLogo19:require("./img/images/Gallery_Logo_19.jpg"),
  GalleryLogo20:require("./img/images/Gallery_Logo_20.jpg"),
};


