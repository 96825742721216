import { Box } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Our_Team_second_content } from "../../Utils/Constants"

const OurTeamComponent =()=>{
  const { t } = useTranslation();
    return(
        <>
        <Box>
        <Box className="Our_Team_title">{t("Our_Team_content_Title1")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub1")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub2")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub3")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub4")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub5")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_content_TitleSub6")}</Box>
      </Box>
      <Box>
        <Box className="Our_Team_title">{t("Our_Team_second_content_Title1")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub1")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub2")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub3")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub4")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub5")}</Box>
        <Box className="AboutUs_Sub_title">{t("Our_Team_second_content_TitleSub6")}</Box>
      </Box>
        </>
    )
}
export default OurTeamComponent