import { Box } from "@mui/system";
import Map from "./Map/Map";
import { ReCAPTCHA_sitekey, contact_us_Phone } from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { ApplicationId } from "../../api/url";
import { postContactUs } from "../../services/contactUsService";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

const ContactUsComponent = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const reCaptchaRef = useRef(null);
  const { t } = useTranslation();

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    message: Yup.string().required("Please Enter message"),
    phone: Yup.string().required("Please Enter Phone Number"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
      }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
      }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
      }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
      }</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
      }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
      }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
      }</td>
        </tr>
      </table>
    <div>
  </div>
`;
    const htmlFormWidthInventory = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
      }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
      }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
      }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
      }</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
      }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
      }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
      }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
      }</td>
          </tr>
        </table>
      <div>
    </div>
  `;
    setIsLoading(true);
    let payload = {
      HtmlContent: state ? htmlFormWidthInventory : htmlForm,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        reCaptchaRef?.current?.reset();
        setIsLoading(false);
        toast.success("email send successfully");
        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Box className="ContactUsComponent">
        <Box>
          <Box className="ContactUs_Component_text">{t("Next_Construction")}</Box>
          <Box className="ContactUs_Component_text">{t("Equipment_Needs")}</Box>
          <Box className="ContactUs_Component_text">{t("Address_Below")}</Box>
          <Box className="ContactUs_Component_text">{t("Equipment_Searching")}</Box>
        </Box>
        <Box>
          <Map />
        </Box>
        <Box>
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              phone: "",
              location: "",
              message: "",
              inventory: decodeURIComponent(state),
              reCaptcha: "",
            }}
            validationSchema={AccSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmitForm(values, resetForm);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid item container spacing={2} sx={{ mt: "10px" }}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <div className="Contact_Page_field">
                      <input
                        type="text"
                        id="userfirstname"
                        name="firstname"
                        className="contact_page_input"
                        placeholder="First Name*"
                        value={values.firstname}
                        onChange={(e) => handleChange(e)}
                      />

                      {errors.firstname && touched.firstname && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.firstname}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <div className="Contact_Page_field ml-2">
                      <input
                        type="text"
                        id="userfirstname"
                        placeholder="Last Name*"
                        name="lastname"
                        className="contact_page_input"
                        value={values.lastname}
                        onChange={(e) => handleChange(e)}
                      />

                      {errors.lastname && touched.lastname && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.lastname}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="Contact_Page_field">
                      <input
                        type="email"
                        id="useremail"
                        placeholder="Email Address*"
                        name="email"
                        className="contact_page_input"
                        value={values.email}
                        onChange={(e) => handleChange(e)}
                      />

                      {errors.email && touched.email && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="Contact_Page_field">
                      <input
                        type="number"
                        id="userfirstname"
                        placeholder=" Phone Number*"
                        name="phone"
                        className="contact_page_input"
                        value={values.phone}
                        onChange={(e) => handleChange(e)}
                      />
                      {errors.phone && touched.phone && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </Grid>
                  {state ?
                    <Grid item xs={12} sm={12} lg={12}>
                      <div className="Contact_Page_field">
                        <input
                          type="text"
                          id="Inventory"
                          placeholder="Inventory"
                          name="inventory"
                          className="contact_page_input"
                          value={values.inventory}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid> : null}
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="Contact_Page_field">
                      <input
                        type="text"
                        id="location"
                        placeholder="Location"
                        name="location"
                        className="contact_page_input"
                        value={values.location}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="contact_grid_box_top Contact_Page_field">
                      <textarea
                        rows="5"
                        type="textarea"
                        id="message"
                        name="message"
                        className="contact_page_input_textarea"
                        value={values.message}
                        placeholder="Tell us what you're looking for and how we can help"
                        onChange={(e) => handleChange(e)}
                      />
                      {errors.message && touched.message && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.message}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="contact_grid_box_top Contact_Page_field google_recaptch_box">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        size="normal"
                        sitekey={ReCAPTCHA_sitekey}
                        onChange={(captchaCode) => {
                          setFieldValue("reCaptcha", captchaCode);
                          // onReCAPTCHAChange
                        }}
                      />
                      {errors.reCaptcha && touched.reCaptcha && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.reCaptcha}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="button_grid">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={
                          isLoading ? "Submit_loading_btn" : "Submit_btn"
                        }
                      >Send Message</button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
        <Box className="ContactUs_address_box">
          <Box className="ContactUs_Component_address_text">{t("Marcel_Equipment_Limited")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Progress_Drive")}</Box>
          <Box className="ContactUs_Component_address_text">{t("London")}</Box>
          <Box className="ContactUs_Component_address_text">{t("N6N_1B8")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Canada")}</Box>
        </Box>
        <Box className="ContactUs_address_box">
          <Box className="ContactUs_Component_address_text">{t("Contact_John")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Phone")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Fax")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Toll_Free")}</Box>
          <Box className="ContactUs_Component_address_text">{t("Email")}<span><a href={`mailto:${contact_us_Phone.Email_Address}`} className="ContactUs_Component_address">{t("Email_Address")}</a></span></Box>
        </Box>
      </Box>
    </>
  );
};
export default ContactUsComponent;
