import "./App.css";
import "./styles/dashboardLayout.css";
import "./styles/dashboard.css";
import "./styles/contact_us.css";
import "./styles/about_us.css";
import "./styles/our_team.css";
import "./styles/reconditioning_process.css";
import "./styles/links.css";
import "./styles/gallery.css";
import "./styles/inventory.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Context from "./helper/Context";
import Dashboard from "./page/Dashboard/Dashboard";
import ContactUs from "./page/ContactUs/ContactUs";
import AboutUs from "./page/AboutUs/AboutUs";
import OurTeam from "./page/OurTeam/OurTeam";
import { Path } from "./Utils/ScreenPath";
import ReconditioningProcess from "./page/ReconditioningProcess/ReconditioningProcess";
import Links from "./page/Links/Links";
import Gallery from "./page/Gallery/Gallery";
import Inventory from "./page/Inventory/Inventory";
import { useEffect, useState } from "react";
import { localStorageGetItem, localStorageSetItem } from "./helper/localStorage";
import "./helper/i18n.js";
import Equipment from "./page/Equipment/Equipment";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";
function App() {
  const [selectLanguage, setSelectLanguage] = useState(localStorageGetItem({ key: "Language" }) ?? "/en/en");

  const handleChangeLanguage = (value) => {
    setSelectLanguage(value);
    // localStorageSetItem("Language",value)
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("assetType");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />

        <Context.Provider value={{ selectLanguage, setSelectLanguage, handleChangeLanguage }}>
          <Routes>
            <Route path={"/:id"} element={<Equipment />} />
            <Route path={Path.Home} element={<Dashboard />} />
            <Route path={Path.Links} element={<Links />} />
            <Route path={Path.Gallery} element={<Gallery />} />
            <Route path={"/inventory/:id"} element={<Inventory />} />
            <Route path={Path.ReconditioningProcess} element={<ReconditioningProcess />} />
            <Route path={Path.OurTeam} element={<OurTeam />} />
            <Route path={Path.AboutUs} element={<AboutUs />} />
            <Route path={`/contact-us/:id`} element={<ContactUs />} />
            <Route path={Path.ContactUs} element={<ContactUs />} />
          </Routes>
        </Context.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
