import { Box } from "@mui/material";
import ReconditioningProcessComponent from "../../components/ReconditioningProcessComponent/ReconditioningProcessComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const ReconditioningProcess = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <Helmet>
        <title>Equipment Reconditioning & Rebuilding | Marcel Equipment</title>
        <meta
          name="description"
          content="Learn about Marcel Equipment's reconditioning & rebuilding process for used construction & landfill equipment. Each machine performs like new before listing."
        />
      </Helmet>
      <DashboardLayout activeTab={"ReconditioningProcess"}>
        <Box className="container">
          <Box className="p-x-20 p-b-50">
            <ReconditioningProcessComponent />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default ReconditioningProcess;
