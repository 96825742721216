import { Box } from "@mui/system";
import { FooterContent, social_media_link } from "../../../Utils/Constants";
import Facebook from "../../../assets/img/svg/facebook.svg";
import Instagram from "../../../assets/img/svg/instagram.svg";
import Linkedin from "../../../assets/img/svg/linkedin.svg";
const Footer = () => {
  return (
    <>
      <Box className="footer">
        <Box className="container">
          <Box className="footer_box">
            <Box className="Footer_social_media">
              <Box className="Footer_social_media_box">
                <a
                  href={social_media_link.Facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    alt=""
                    className="Footer_social_media_Icon"
                  />
                </a>
              </Box>
              <Box className="Footer_social_media_box">
                <a
                  href={social_media_link.Instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    alt=""
                    className="Footer_social_media_Icon"
                  />
                </a>
              </Box>
              <Box className="Footer_social_media_box">
                <a
                  href={social_media_link.Linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Linkedin}
                    alt=""
                    className="Footer_social_media_Icon"
                  />
                </a>
              </Box>
            </Box>
            <Box className="Footer_text">
            © {(new Date().getFullYear())} · Marcel · Site by
              <span>
                {" "}
                <a
                  href={FooterContent.WEB_LINK}
                  className="Footer_text_Web_Link"
                  target="_blank"
                >
                  {FooterContent.LINK_NAME}
                </a>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Footer;
