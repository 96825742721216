import { Box, Drawer } from "@mui/material";
import { HeaderNavigationLabel } from "../../../Utils/Constants";
import imagePath from "../../../assets/imagePath";
import Btn from "../../../components/commonComponent/HeaderLanguageBtn/Btn";
import {
  ContactDetels,
  DashboardLayoutContent,
} from "../../../Utils/Constants";
import { Path } from "../../../Utils/ScreenPath";
import Context from "../../../helper/Context";
import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import i18n from "../../../helper/i18n";
import { useTranslation } from "react-i18next";
import { GoogleTranslate } from "../../../hooks/useGoogleTranslate";

const NavigationList = ({ activeTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box
        className={
          activeTab === "Home"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => {
          navigate(Path.Home);
          localStorage.removeItem("assetType");
        }}
      >
        {t("HeaderNavigationLabel_Home")}
      </Box>
      <Box
        className={
          activeTab === "ReconditioningProcess"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.ReconditioningProcess)}
      >
        {t("HeaderNavigationLabel_Reconditioning_Process")}
      </Box>
      <Box
        className={
          activeTab === "OurTeam"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.OurTeam)}
      >
        {t("HeaderNavigationLabel_Our_Team")}
      </Box>
      <Box
        className={
          activeTab === "AboutUs"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.AboutUs)}
      >
        {t("HeaderNavigationLabel_About_Us")}
      </Box>
      <Box
        className={
          activeTab === "Gallery"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.Gallery)}
      >
        {t("HeaderNavigationLabel_Gallery")}
      </Box>
      <Box
        className={
          activeTab === "Links"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.Links)}
      >
        {t("HeaderNavigationLabel_Links")}
      </Box>
      <Box
        className={
          activeTab === "ContactUs"
            ? "header_navigation_label_active"
            : "header_navigation_label"
        }
        onClick={() => navigate(Path.ContactUs)}
      >
        {t("HeaderNavigationLabel_Contact_Us")}
      </Box>
    </>
  );
};

const Header = ({ activeTab, subHeaderCustomerContainer, selectPage }) => {
  const [midScreenMenuBar, setMidScreenMenuBar] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMidScreenMenuClose = () => {
    setMidScreenMenuBar(false);
  };

  const handleMidScreenMenuOpen = () => {
    setMidScreenMenuBar(true);
  };
  return (
    <>
      <Box className="header_background">
        <Box className="header">
          <Box className="container">
            <Box className="header_box">
              <Box
                onClick={() => {
                  navigate(`/`);
                  localStorage.removeItem("assetType");
                }}
                className="header_logo"
              >
                <img
                  src={imagePath.Logo}
                  alt=""
                  height={localStorage.getItem("language") === "fr"?"45px":"80px"}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box className="header_center_box">
                <Box className="header_contact_box">
                  <a
                    href={`tel:519-686-1123`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box className="header_contact_text">{t("PH_TXT")}</Box>
                  </a>
                  <a
                    href={`tel:800-265-5747`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box className="header_contact_text">{t("TF_PH")}</Box>
                  </a>
                </Box>
                <Box className="header_slogan_box">
                  <Box className="header_slogan_text" fontSize={localStorage.getItem("language") === "fr"?"12px":"14px"}>{t("Slogan")}</Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <GoogleTranslate/>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {!selectPage && (
        <Box className="container">
          <Box className="header_navigation_box_lg_screen">
            <Box className={`${subHeaderCustomerContainer} d-flex`}>
              <NavigationList activeTab={activeTab} />
            </Box>
          </Box>

          <Box className="header_navigation_box_md_screen">
            <MenuIcon
              className="header_navigation_List_Menu_btn"
              onClick={handleMidScreenMenuOpen}
            />
          </Box>
        </Box>
      )}
      <Drawer
        anchor={"left"}
        open={midScreenMenuBar}
        onClose={handleMidScreenMenuClose}
      >
        <Box
          sx={{ width: "100vw" }}
          role="presentation"
          onClick={handleMidScreenMenuClose}
          onKeyDown={handleMidScreenMenuClose}
        >
          <Box className="container">
            <Box className="header_navigation_menu_close">
              <CloseIcon
                className="header_navigation_List_Menu_btn"
                onClick={handleMidScreenMenuOpen}
              />
            </Box>
            <Box className="header_navigation_list_box_md_screen">
              <NavigationList activeTab={activeTab} />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default Header;
