import Header from "./Header/Header";
import Footer from "./Footer/Footer";
const DashboardLayout = ({ children, activeTab, subHeaderCustomerContainer, selectPage }) => {
  return (
    <>
      <Header activeTab={activeTab} subHeaderCustomerContainer={subHeaderCustomerContainer} selectPage={selectPage}/>
      {children}
      <Footer />
    </>
  );
};
export default DashboardLayout;
