import { Box } from "@mui/material";
import InventoryComponent from "../../components/InventoryComponent/InventoryComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";

const Inventory = () => {
  const [selectPage, setSelectPage] = useState(false);
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <DashboardLayout selectPage={selectPage}>
        <Box className="container">
          <Box className="p-x-20 p-b-50">
            <InventoryComponent selectPage={selectPage} setSelectPage={setSelectPage} />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default Inventory;
