import imagePath from "../assets/imagePath";
export const ReCAPTCHA_sitekey = "6Ld8hx4pAAAAAOnDKuofE10gPgNROp_os0gxetWb";
export const DashboardLayoutContent = {
  Slogan: "“DOING IT RIGHT TODAY…TO EARN YOUR BUSINESS TOMORROW.”",
  BtnContent: {
    EN: "EN",
    ES: "ES",
    FR: "FR",
  },
};

export const HeaderNavigationLabel = {
  Home: "Home",
  Reconditioning_Process: "Reconditioning Process",
  Our_Team: "Our Team",
  About_Us: "About Us",
  Gallery: "Gallery",
  Links: "Links",
  Contact_Us: "Contact Us",
};

export const ContactDetels = {
  PH_TXT: "PH/TXT: 519-686-1123",
  TF_PH: "TF - PH:  800-265-5747",
};

export const FooterContent = {
  SITS_BY: "© 2022 · Marcel · Site by",
  WEB_LINK: "https://vizybilitydms.com/",
  LINK_NAME: "Vizybility DMS",
};

export const social_media_link = {
  Facebook:
    "https://www.facebook.com/Marcel-Equipment-Limited-839883946137208/",
  Instagram: "https://www.instagram.com/marcelequipment",
  Linkedin: "https://www.linkedin.com/company/marcel-equipment-ltd",
};

export const dashboard_navigon_link_list = [
  {
    title: "LANDFILL COMPACTORS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WASTE HANDLER DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "CRAWLER DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WHEEL DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WHEEL LOADERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "ROCK TRUCKS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "ARTICULATED TRUCKS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "EXCAVATORS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
];

export const contact_us_content = {
  Next_Construction:
    "Are you looking for equipment for your next construction project or landfill?",

  Equipment_Needs: "We can assist with your equipment needs.",

  Address_Below: "Send us an email at the address below.",

  Equipment_Searching:
    "We'd like to hear from you and help source the equipment you are searching for.",
};

export const contact_us_address = {
  Marcel_Equipment_Limited: "Marcel Equipment Limited",
  Progress_Drive: "1000 Progress Drive",
  London: "London, Ontario",
  N6N_1B8: "N6N 1B8",
  Canada: "Canada",
};

export const contact_us_Phone = {
  Contact_John: "Contact John or Mark",
  Phone: "Phone or Text: 519-686-1123",
  Fax: "Fax: 519-686-9350",
  Toll_Free: "Toll Free: 800-265-5747",
  Email: "Email:",
  Email_Address: "sales@marcelequipment.com",
};

export const About_Us_content = {
  About_Us: "About  Us",
  Title1:
    "Renting equipment is a cost-effective alternative to purchasing and allows you to meet those peak production periods for your jobsites. It also allows you to bid on jobs that you might not otherwise bid on if you did not have the required equipment within your own fleet. We can offer flexible rental periods such as monthly, weekly and even hourly under certain conditions.",
  Title2:
    "Marcel Equipment also provides Rental with Purchase Option (RPO). Why not try it before you buy it! With an RPO option, you get to know the machine better than we do. You can put it to work, ensure it does the job for your application. It puts the risk on Marcel Equipment should an issue arise with the machine, so it is not at your cost. Come the end of the rental term, you have the option to return it if not completely satisfied. If it does what we said it would and meets your needs, you can convert it to a purchase and already have some equity/down payment built in by applying the paid rentals rent to the purchase price.",
};

export const About_Us_second_content = {
  Title1: "End of Lease, Trade-In, or Surplus Equipment?",
  Title2:
    "80% of the time we offer more than the lease return/buy out price or trade-in value if we can be part of your bidding process. ",

  Title3:
    "Whether you are returning a leased Landfill Compactor or Waste Handler Crawler tractor or offering it as a Trade-in, we would welcome the opportunity from you to be able to offer you a purchase price for the equipment or to participate in your bidding process.",

  Title4:
    "So whether you are looking to Buy or Sell Landfill or Construction equipment, Marcel Equipment has the Quality Inventory, Experienced staff and the Expertise to accommodate your needs.",

  Title5:
    "Please call Mark or John today at: 1-800-265-5747 or call or text to 1-519-686-1123 or via email to: sales@marcelequipment.com",

  Title6:
    "Thank you for your interest in Marcel Equipment Limited and we look forward to working with you.",
};

export const Our_Team_content = {
  Title1: "Mark Frederick — General Manager",
  TitleSub1:
    "Mark has over 25 years of experience in the heavy equipment industry. He was always passionate about heavy equipment, right from a toddler who began with Tonka trucks in the sandbox, to fueling and greasing machines when he was old enough to work for his relatives in the construction industry and eventually operating equipment.",

  TitleSub2:
    "After going to University for his BBA, he knew a job in the financial and conventional business environment was not where his passion was. His passion was to be in the heavy equipment industry. As a result, he made the decision to go to college to become a heavy equipment technician. After college, he accepted a job with the local Cat dealer where he managed to skip getting his hands dirty and went into their Management Trainee program. Mark spent close to 15 years working at the Cat dealership through various jobs on the product support side of the business.",

  TitleSub3:
    "In 2014, Marcel Equipment approached him with an opportunity to join the company. It was a tough decision to leave a company that provided him with many great opportunities and one that he thought he would retire from. However, in the end and already having a close relationship with Marcel Equipment, the move made sense for him and his family and he joined the team. He believes in doing things right the first time; as well as being fair and honest, which is a perfect fit for the trustworthy reputation of Marcel Equipment.  He works by the motto…would you feel comfortable selling this piece of equipment to your own father.",

  TitleSub4:
    "Today, he manages the business at Marcel Equipment for Groupe Poisson and loves coming into work each day to face the rewards and challenges of the industry.",

  TitleSub5:
    "Mark and his wife Tiffany have two young children. His hobbies include playing hockey, fishing and snowmobiling.",

  TitleSub6:
    "Mark looks forward to continuing the tradition of providing quality used equipment for Marcel Equipment and building upon the Marcel Equipment reputation and name that has been built over the past 45 years.",
};

export const Our_Team_second_content = {
  Title1: "John Stewart – Sales Manager",
  TitleSub1:
    "John has 50 years of experience in the heavy equipment Sales and Servicing industry. He is a licensed Heavy Equipment Technician by trade and uses that experience everyday sourcing and selling equipment for Marcel Equipment Limited.",

  TitleSub2:
    "He has been the Sales Manager of Marcel Equipment Ltd. for over 25 years and has a great knowledge of all types of construction equipment, especially the Landfill Compactors and Waste Handler Crawler Tractors that Marcel Equipment specializes in.",

  TitleSub3:
    "He is an old farm boy that grew up operating and fixing all types of farm and construction equipment. In his off time, he enjoys fishing, landscaping, remodeling properties and beekeeping, which he says teaches him patience.",

  TitleSub4:
    "John has three grown sons & several grandchildren to enjoy",

  TitleSub5:
    "John welcomes your calls and inquiries, be it to discuss an application, what type of equipment to specify in your bid documents or to purchase a machine. In a complex industry with many options, let him help you in determining the best possible equipment to complete the work most effectively.",

  TitleSub6:
    "We hope you enjoy working with Mark and John who are good, honest people who make it easy to do business with Marcel Equipment.",
};

export const Reconditioning_Process_content = {
  Title1: "Marcel Equipment’s Machine Preparation Processes",

  TitleSub1:
    "Buying used heavy equipment can be a nerve-racking experience even for the most experienced purchaser. Questions spin around your head; do you know who you are buying from, are they well known in the industry, do they have any references, how long have they been in business, do they own the piece of equipment they are selling, do they have any previous history on the machine, how can I be confident in the machine I am buying, was it an insurance write-off, will I get what they say I will, is it as good as they are saying it is, why does it not come with any warranty?",

  TitleSub2:
    "We at Marcel Equipment are here to take all that guess work away from you and eliminate the potential nerve-racking experience. Let us do the worrying for you and take on the risk.",

  TitleSub3:
    "At Marcel Equipment, each employee takes pride in the product we sell. We stand behind our product and everyone of our employees are proud of each machine that leaves here with the Marcel Equipment name on it. ",

  TitleSub4:
    "We are fully transparent in everything we do.  We provide complete and factual details on each machine including visual inspection results, operational testing results, pressure testing results, current and previous repair history, Cat undercarriage inspection reports, pictures and specifications. We take pride in providing you with complete details on everything we know. We go above and beyond anyone else in the industry to ensure that you know the full details on what you are purchasing. Nothing leaves our yard with a known issue. We complete all needed repairs the right way with no short cuts.  We can provide many references from our past customers whom have purchased one unit from us or multiple units over the years.",

  TitleSub5:
    "In order to eliminate risks for both ourselves and the end user, we follow stringent processes for each machine. That is why we have developed and fine tuned our reconditioning process over the last 45 years.",
};

export const Reconditioning_Process_second_content = {
  Title1: "MARCEL EQUIPMENT’S RECONDITIONING PROCESS",

  TitleSub1:
    "Prior to purchase, we complete background history checks and/or have the machines inspected in order to weed out any machine that may have any serious mechanical problems or be from a previous detrimental application.",

  TitleSub2:
    "Once the machine arrives to our yard, all panels and covers are removed and machines are completely power washed.",

  TitleSub3:
    "Our shop foreman then completes his inspection, listing any items that require attention or repair.",

  TitleSub4:
    "Machines are then put through a complete operational testing process and visual inspection by our mechanics.",

  TitleSub5:
    "They are then serviced and all filters are cut open to inspect for contaminates.",

  TitleSub6:
    "All suction screens, magnetic plugs and strainers are checked closely for contaminates.",
  TitleSub7:
    "Non-filtered oils are also checked for contaminates and discolouration.",
  TitleSub8:
    "Pressure checks are completed and adjusted on the engine, transmission and all hydraulic systems to ensure they are within OEM spec.",
  TitleSub9:
    "Performance checks are completed such as; engine high and low idle speeds, turbo boost, torque converter stall speeds and engine performance to ensure correct engine horsepower as well as to ensure the transmission and torque converter are operating at optimum efficiency.",
  TitleSub10:
    "Once the inspection and servicing are complete, all required repairs are completed which may include but not limited to;",

  SubTitle10point1: "Electrical system",
  SubTitle10point2: "Monitoring system",
  SubTitle10point3: "Fuel system",
  SubTitle10point4: "Steering system",
  SubTitle10point5: "Air intake and Exhaust system",
  SubTitle10point6: "Cooling system",
  SubTitle10point7: "Changing of hoses, tubes and lines",
  SubTitle10point8: "Hydraulic cylinders",
  SubTitle10point9: "Air conditioning and heating system",
  SubTitle10point10: "Glass replacement",
  SubTitle10point11: "Operator station repairs",
  SubTitle10point12: "Undercarriage repairs and replacement",
  SubTitle10point13: "Joint wear at pins and bushings",
  SubTitle10point14: "Line boring of joint pin bosses",
  SubTitle10point15: "Axle oscillation points",
  SubTitle10point16: "Grease systems and/or lines",
  SubTitle10point17:
    "All needed repairs are completed by our knowledgeable staff.",
  SubTitle10point18:
    "Machines are then operated and rechecked for any further required repairs.",
  SubTitle10point19:
    "Machines then move to our welding & cosmetic repair shop where welding repairs are completed to the frame structures.",
  SubTitle10point20: "Railings, steps and sheet metal repairs are completed.",
  SubTitle10point21:
    "Machines are then taped up, sandblasted, primed, painted and new decals installed in our painting facility.",
  SubTitle10point22:
    "Our shop foreman completes one last visual and operational inspection.",
  SubTitle10point23:
    "Machines then go through our Pre-Delivery Inspection (PDI) before delivery takes place.",
  SubTitle10point24:
    "At the end of the inspection, the technician is asked…Would you be happy if you purchased this machine? The unit will not be shipped until the answer is YES.  ",

  TitleSub11:
    "This process ensures machines are work ready and in top operating condition when delivered to our customers.",

  TitleSub12: "This is our Process… ",
  TitleSub12point1: "“Doing it Right Today to Earn Your Business Tomorrow”",
};

export const Reconditioning_Process_third_content = {
  Title1: "REBUILD AND RECONDITIONING PROCESS",

  TitleSub1: `The primary benefit of purchasing a piece of rebuilt equipment is cost savings. In many cases the price on a rebuilt machine is significantly less than that of a new machine. Looking at it another way, you can sometimes buy two pieces of reconditioned equipment for the same amount of money you would spend on one new machine. You can end up with equipment that is "like new" at far less cost and, in the process, improve the reliability and availability of your fleet.`,

  TitleSub2:
    "Before deciding if rebuilt equipment is right for you, it is important to consider many factors.",

  TitleSub3:
    "Rebuilt equipment is used equipment that has undergone steps to offset the effects of wear and tear. Unlike most used equipment, rebuilt equipment is refurbished to improve its condition. However, the degree to which a machine is reconditioned depends on two things: the vendor that is performing the process and the standards and guidelines they follow.",

  TitleSub4:
    "Like the definition of rebuilt, the scope of work varies. The best rebuilding and reconditioning programs include a rigorous process that performs a complete teardown of the machine and looks at all the major systems: electrical, hydraulic, drivetrain, structural components, operator station, hoses and cylinders. ",

  TitleSub5:
    "Carefully consider whom the provider is as you shop for rebuilt/reconditioned equipment.  Begin your search by asking for a detailed description of the scope of work the provider performs. What items in the major systems are typically replaced, rebuilt or repaired? What systems or components are not replaced? Are machine parts rebuilt by an OEM or to OEM standards?  What is the previous history on the machine? Do they have a serial number of the machine? An important part of any rebuild program is to start with a good core machine.",

  TitleSub6:
    "Finally, consider the experience of the provider - how long have they rebuilt equipment, how many machines have they rebuilt, and how frequently do they rebuild equipment? It is also a good idea to ask for and check several references to be sure they will attest to the quality of the provider's work.",
  TitleSub7:
    "Let Marcel Equipment answer all those questions for you and instil the confidence you need to make an informed and educated decision.    ",
  TitleSub8:
    "At Marcel Equipment, we have been rebuilding Landfill Compactors and Dozers for over 40 years.",
  TitleSub9:
    "Our experienced tradespeople know what to look for, what areas are problematic, what needs to be completed to increase reliability and how to produce an end product that can go right to work with no issues.",
  TitleSub10:
    "The rebuilt units go through our typical reconditioning process but can also include rebuilding or replacement of the machines;",

  SubTitle10point1: "Engine",
  SubTitle10point2: "Torque convertor",
  SubTitle10point3: "Transmission",
  SubTitle10point4: "Transfer case",
  SubTitle10point5: "Drive line",
  SubTitle10point6: "Hydraulic pumps and motors",
  SubTitle10point7: "Complete fuel systems",
  SubTitle10point8: "Wiring Harnesses",
  SubTitle10point9: "Cooling assemblies",
  SubTitle10point10: "Final drives",
  SubTitle10point11: "Axles",
  SubTitle10point12: "Hydraulic cylinders",
  SubTitle10point13: "Cab reconditioning",
  TitleSub11:
    "We can offer Marcel Equipment Rebuilds using our experienced technicians like we have done for years with great success.",
  TitleSub12: "Or",
  TitleSub13:
    "In conjunction with our local Cat dealer, we can offer units with Cat Certified Rebuild Machine Components (CMCR) which can carry extended warranties supported by Cat dealers all over North America. In working with the Cat dealer, the engine, torque, transmission and transfer case are rebuilt at their rebuild facility. The components are rebuilt to strict guidelines and procedures for Cat Certified specifications and bench/dyno tested upon completion.",
  TitleSub14: "Marcel Equipment Limited is… ",
  TitleSub14spoint1: "“Doing it Right Today to Earn Your Business Tomorrow”",
};

export const Link_List = {
  Logo1Link: "https://www.groupepoisson.com/",
  Logo2Link: "https://selectcivil.com.au/",
  Logo3Link: "https://www.iedagroup.com/",
  Logo4Link: "https://marcelequipment.com/links/www.terracompactorwheel.com",
  Logo5Link: "https://swana.org/",
  Logo6Link: "https://aors.on.ca/",
  Logo7Link: "https://www.owma.org/cpages/home",
  Logo8Link: "https://municipalwaste.ca/",
  Logo9Link: "http://ldhca.com/",
  Logo11Link: "https://pipeline.ca/",
  Logo12Link: "https://www.shearpowercorp.com/",
  Logo13Link: "https://www.equipmentjournal.com/",
};

export const Gallery_List = [
  {
    logo: imagePath.GalleryLogo1,
    link: "https://www.instagram.com/p/CapuaZ9qjt6/",
  },
  {
    logo: imagePath.GalleryLogo2,
    link: "https://www.instagram.com/p/Caaqg9sqk70/",
  },
  {
    logo: imagePath.GalleryLogo3,
    link: "https://www.instagram.com/p/CaPYbIBKgxE/",
  },
  {
    logo: imagePath.GalleryLogo4,
    link: "https://www.instagram.com/p/CaFNruIqFbK/",
  },
  {
    logo: imagePath.GalleryLogo5,
    link: "https://www.instagram.com/p/CZ1unvNKXe-/",
  },
  {
    logo: imagePath.GalleryLogo6,
    link: "https://www.instagram.com/p/CZkZUFrKaQ6/",
  },
  {
    logo: imagePath.GalleryLogo7,
    link: "https://www.instagram.com/p/CZR09RKqVWf/",
  },
  {
    logo: imagePath.GalleryLogo8,
    link: "https://www.instagram.com/p/CZM-nENqZcw/",
  },
  {
    logo: imagePath.GalleryLogo9,
    link: "https://www.instagram.com/p/CY9llVWK8Ny/",
  },
  {
    logo: imagePath.GalleryLogo10,
    link: "https://www.instagram.com/p/CYuUc-mKNxq/",
  },
  {
    logo: imagePath.GalleryLogo11,
    link: "https://www.instagram.com/p/CYjci_IKQdW/",
  },
  {
    logo: imagePath.GalleryLogo12,
    link: "https://www.instagram.com/p/CYWuTo0q-pU/",
  },
  {
    logo: imagePath.GalleryLogo13,
    link: "https://www.instagram.com/p/CYKH_YQqs1J/",
  },
  {
    logo: imagePath.GalleryLogo14,
    link: "https://www.instagram.com/p/CX4GVezKP4J/",
  },
  {
    logo: imagePath.GalleryLogo15,
    link: "https://www.instagram.com/p/CXwDd-jKsHc/",
  },
  {
    logo: imagePath.GalleryLogo16,
    link: "https://www.instagram.com/p/CXg5-h1KJ7a/",
  },
  {
    logo: imagePath.GalleryLogo17,
    link: "https://www.instagram.com/p/CXMSfm5KxNc/",
  },
  {
    logo: imagePath.GalleryLogo18,
    link: "https://www.instagram.com/p/CW6RldcqheP/",
  },
  {
    logo: imagePath.GalleryLogo19,
    link: "https://www.instagram.com/p/CWoG-e1qBGh/",
  },
  {
    logo: imagePath.GalleryLogo20,
    link: "https://www.instagram.com/p/CWYwG26IPeW/",
  },
];

export const Dashboard_Content = {
  MACHINE_HOURS: "MACHINE HOURS",
  Landfill_Equipment_Rebuilder:
    "A global landfill equipment rebuilder that has supplied reconditioned and work ready landfill compactors, earthmoving and construction equipment since 1976.",
  Next: "Next",
  Prev: "Prev",
  Click_here: "Click here for more details and pricing",
};

export const Inventory_Content = {
  Price: "Price",
  Description: "Description",
  MACHINE_HOURS:"MACHINE HOURS",
  ECM_IDLE_HOURS:"ECM IDLE HOURS",
  LOAD_FACTOR:"LOAD FACTOR",
  NEUTRAL_HOURS:"NEUTRAL HOURS"
};

export const Inventory_Details = {
  Model: "CAT 836K (2018)  UNIT# C6783",
  MACHINE_HOURS: "9875",
  ECM_IDLE_HOURS: "2142",
  Price_CAD: " $0.00 CAD",
  Price_USD: " $0.00 USD",
  Description: [
    "WARRANTY INCLUDED",
    "CAT C18 ACERT Engine",
    "Block Heater",
    "Powershift Transmission",
    "Cab (ROPS) with A/C",
    "Pressurized Cab Pre-Cleaner",
    "CAT SU Landfill Blade",
    "CAT Combination Compaction Wheels",
    "Front & Rear No Spin Differentials",
    "Front & Rear Striker Bars",
    "STIC Steering System",
    "Front Wheel Cleaners",
    "Hydraulic Belly Pans",
    "Auto Reverse Cooling Fan",
    "Front Windshield Guard",
    "Rear Rad Guard",
    "Stop & Flood Light Arrangement",
    "Ansul Fire Suppression",
    "Rear View Camera",
    "Air Turbine Engine Pre-Cleaner",
    "Swing Out Stairs",
    "Heated Mirrors",
    "Fully Maintained by CAT from new",
    "Rated at 562 HP",
    "Weight 123,500 LBS",
  ],
  Details:{
    Title:"Repair / Condition Report",
    SubTitle:"The following are repairs completed by the CAT Dealer prior to Marcel Equipment owning the machine;",
    SubTitle2:"This unit had all OEM servicing & repairs completed by CAT dealer from new.",
    SubTitle3:"At 9,057 hours, service letter for transmission/transfer case was completed",
    SubTitle3Point1:"Torque converter was RECONDITIONED",
    SubTitle3Point2:"Transmission was RECONDITIONED",
    SubTitle3Point3:"Transmission oil pump was RECONDITIONED",
    SubTitle3Point4:"Transmission oil cooler REPLACED",
    SubTitle4:"At 9,070 hours, NEW NRS cooler installed",
    SubTitle5:"At 9,588 hours, REMAN DPF filter was installed",
    SubTitle6:"At 9,868 hours, 2,000 hour service was completed",
  }
};
