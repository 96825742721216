import { Box, Grid } from "@mui/material";
import imagePath from "../../assets/imagePath";
import { Link_List } from "../../Utils/Constants";

const LinksComponent = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo1Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo1} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo2Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo2} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo3Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo3} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo4Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo4} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo5Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo5} alt="" width={"auto"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo6Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo6} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo7Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo7} alt="" width={"auto"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo8Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo8} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo9Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo9} alt="" width={"100%"} />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href="" target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo10} alt="" width={"100%"} />
            </a>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo11Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo11} alt="" width={"100%"}/>
            </a>
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo12Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo12} alt="" width={"100%"} />
            </a>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <a href={Link_List.Logo13Link} target="_blank" className="Link_Box" rel="noreferrer">
              <img src={imagePath.LinkLogo13} alt="" width={"100%"} />
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default LinksComponent;
