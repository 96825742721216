import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Reconditioning_Process_content,
  Reconditioning_Process_second_content,
  Reconditioning_Process_third_content,
} from "../../Utils/Constants";

const ReconditioningProcessComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Box className="Reconditioning_Process_title">
          {t("Reconditioning_Process_content_Title1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title"></Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_content_TitleSub1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_content_TitleSub2")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_content_TitleSub3")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_content_TitleSub4")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_content_TitleSub5")}
        </Box>
      </Box>
      <Box>
        <Box className="Our_Team_title">
          {t("Reconditioning_Process_second_content_Title1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub2")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub3")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub4")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub5")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub6")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub7")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub8")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub9")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title_bottom">
          {t("Reconditioning_Process_second_content_TitleSub10")}
        </Box>
        <ul>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point1")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point2")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point3")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point4")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point5")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point6")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point7")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point8")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point9")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point10")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point11")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point12")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point13")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point14")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point15")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point16")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point17")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point18")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point19")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point20")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point21")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point22")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point23")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_second_content_SubTitle10point24")}
            </Box>
          </li>
        </ul>
        <Box className="Reconditioning_Process_Sub_title_bottom_tow">
          {t("Reconditioning_Process_second_content_TitleSub11")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_second_content_TitleSub12")}<span className="Reconditioning_Process_Sub_titleSub">{t("Reconditioning_Process_second_content_TitleSub12point1")}</span>
        </Box>
      </Box>
      <Box>
        <Box className="Our_Team_title">
          {t("Reconditioning_Process_third_content_Title1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub1")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub2")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub3")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub4")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub5")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub6")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub7")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub8")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub9")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title_bottom">
          {t("Reconditioning_Process_third_content_TitleSub10")}
        </Box>
        <ul>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point1")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point2")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point3")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point4")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point5")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point6")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point7")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point8")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point9")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point10")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point11")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point12")}
            </Box>
          </li>
          <li>
            <Box className="Reconditioning_Process_Sub_title_point">
              {t("Reconditioning_Process_third_content_SubTitle10point13")}
            </Box>
          </li>
        </ul>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub11")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub12")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub13")}
        </Box>
        <Box className="Reconditioning_Process_Sub_title">
          {t("Reconditioning_Process_third_content_TitleSub14")}<span className="Reconditioning_Process_Sub_titleSub">{t("Reconditioning_Process_third_content_TitleSub14spoint1")}</span>
        </Box>
      </Box>
    </>
  );
};
export default ReconditioningProcessComponent;
