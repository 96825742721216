import { Box } from "@mui/system";
import { Dashboard_Content } from "../../../Utils/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../../Utils/ScreenPath";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { postMachinesList } from "../../../services/machineService";
import { ApplicationId } from "../../../api/url";

const MachineBox = ({ MachineDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box className="MachineList_Box">
        <Box className="MachineList_img">
          <img src={MachineDetails.primary_image.url} alt="" width={"100%"} />
        </Box>
        <Box className="MachineList_dis">
          <Box>
            <Box className="MachineList_Title_Box">
              <Box className="MachineList_Title MachineList_Title_Hover"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  const encodeURICom = encodeURIComponent(
                    `${MachineDetails.assetAdvertisementId
                    }-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                  );
                  navigate(`${Path.Inventory}/${encodeURICom}`, { state: MachineDetails });
                  // window.location.reload();
                }}
              >
                {/* {MachineDetails.manufacturer?.name} */}

                {MachineDetails?.model?.manufacturer?.name} {MachineDetails?.model?.number} {MachineDetails?.year ? `(${MachineDetails?.year})` : null} {MachineDetails?.unit_number ? ` UNIT # ${MachineDetails?.unit_number}` : null}
              </Box>
              {MachineDetails.meter_reading ?
                <Box className="MachineList_Title">
                  {t("Dashboard_Content_MACHINE_HOURS")}: {MachineDetails.meter_reading}
                </Box> : <Box className="MachineList_Title">
                  {t("Dashboard_Content_MACHINE_HOURS")}: N/A
                </Box>}
            </Box>
            <Box>
              <Box className="MachineList_description">
                {MachineDetails.description}

                <div
                  dangerouslySetInnerHTML={{
                    __html: MachineDetails?.inlist_description_html,
                  }}
                ></div>
                {/* <div className='EquipmentCard_DETAIL_Box'>
                  <div className='EquipmentCard_DETAILS'>DETAILS:</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.manufacturer?.name}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.model?.number}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.year}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.meter_reading}</div>
                </div> */}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              className="MachineList_Click_here_Text"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                const encodeURICom = encodeURIComponent(
                  `${MachineDetails.assetAdvertisementId
                  }-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                );
                navigate(`${Path.Inventory}/${encodeURICom}`, { state: MachineDetails });
                // window.location.reload();
              }}>
              {t("Dashboard_Content_Click_here")}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const MachineListComponent = ({ selectCategoryType, isMachineData }) => {
  return (
    <>
      <Box className="MachineList_Category_box">
        <Box className="MachineList_Category_title">
          {selectCategoryType?.CategoryName}
        </Box>
        <Box className="d-flex flex-col">
          {isMachineData.map((MachineDetails) => {
            return <MachineBox MachineDetails={MachineDetails} />;
          })}
        </Box>
      </Box>
    </>
  );
};
export default MachineListComponent;
