import React from "react";
import Btn from "../components/commonComponent/HeaderLanguageBtn/Btn";
import { useContext } from "react";
import Context from "../helper/Context";
import { useEffect } from "react";

const languages = [
  { label: "EN", value: "en", src: "https://flagcdn.com/h60/us.png" },
  // { label: "ES", value: "es", src: "https://flagcdn.com/h60/us.png" },
  { label: "FR", value: "fr", src: "https://flagcdn.com/h60/us.png" },
  // Add additional languages as needed
];
const includedLanguages = languages.map((lang) => lang.value).join(",");

function googleTranslateElementInit() {
  new window.google.translate.TranslateElement(
    {
      pageLanguage: "auto",
      includedLanguages,
    },
    "google_translate_element"
  );
}

export function GoogleTranslate() {
  const defaultLang = localStorage.getItem("language") ?? "en";

  const { handleChangeLanguage } = useContext(Context);
  const [langCookie, setLangCookie] = React.useState(`/en/${defaultLang}`);

  React.useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    script.onload = () => {
      // Automatically set language based on browser language or default to 'en'
      const browserLang = defaultLang; // Detect primary language code from the browser
      const defaultLang = languages.find((lang) => lang.value === browserLang)
        ? browserLang
        : "en";

      // setLangCookie(`/en/${defaultLang}`);
      // handleChangeLanguage(`/en/${defaultLang}`);
      // setTimeout(() => onChange(defaultLang), 500); // Delay to ensure the translate element is loaded
    };
    document.body.appendChild(script);
    const style = document.createElement("style");
    style.innerHTML = `
      html { height: auto !important; }
      body { position: static !important; top: 0px !important;}
      iframe.skiptranslate { display: none !important; width:1px !important; height: 1px !important }
    `;
    document.head.appendChild(style);
  }, []);

  const onChange = (value) => {
    console.log("value", value);
    const lang = "/en/" + value;
    setLangCookie(lang);
    localStorage.setItem("language", value);
    handleChangeLanguage(lang);
    
    // window.location.reload();
    const setLanguage = () => {
      const element = document.querySelector(".goog-te-combo");
      if (element) {
        element.value = value;
        element.dispatchEvent(new Event("change"));
      } else {
        setTimeout(setLanguage, 100); // Retry after 100ms if the element is not found
      }
    };

    setLanguage();
  };

  return (
    <div>
      <div
        id="google_translate_element"
        style={{ visibility: "hidden", width: "1px", height: "1px" }}
      ></div>
      <LanguageSelector onChange={onChange} value={langCookie} />
    </div>
  );
}

function LanguageSelector({ onChange, value }) {
  const langCookie = value.split("/")[2];
  return (
    <>
      {/* Do not translate: This part should remain in its original language */}
      {/* Do not translate: Begin */}
      {languages.map((it) => {
        return (
          <Btn
            label={it?.label}
            active={langCookie === it?.value ? true : false}
            onClick={() => onChange(it?.value)}
          />
        );
      })}
    </>
  );
}
