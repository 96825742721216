import { Box } from "@mui/material";
import LinksComponent from "../../components/LinksComponent/LinksComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const Links = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <DashboardLayout activeTab={"Links"}>
        <Box className="container">
          <Box className="p-x-20 p-y-50">
            <LinksComponent />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default Links;
