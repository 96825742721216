export const ApplicationId = "d09b54f5-9e22-434a-92d8-0f6e0b5e44d4";
export const PRODURL = 'https://dealerwebservice-api.azurewebsites.net/'
export const TestURL = 'https://dealerwebservice-api-dev.azurewebsites.net/'
// ? Machine List
export const MACHINE_LIST = 'DealerWebApp/GetInventory'
export const MACHINE_DETAILS = 'DealerWebApp/GetInventoryDetailById?assetId='
export const GET_MACHINES_LIST = 'DealerWebApp/GetInventory'
export const GET_RELATED_POST =
  'DealerWebApp/GetInventoryByCategory?assetTypeId='
  export const GET_MACHINES_DETAILS =
  `DealerWebApp/GetInventorySummaryById?applicationId=${ApplicationId}`

export const GET_CATEGORY_LIST =
  `DealerWebApp/GetTierWiseAssetCategorizationSummary?applicationId=${ApplicationId}`
export const POST_SAVEADVERTISE = 'DealerWebApp/SaveAdvertiseVisitLogs'
export const POST_CONTACT_US = 'DealerWebApp/SendContactEmail'
export const GET_TESTIMONIAL = `DealerWebApp/GetTestimonialSummary?applicationId=${ApplicationId}`
export const URL = PRODURL
